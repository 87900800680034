import { gql, useMutation, useQuery } from '@apollo/client';
import {
    Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, MenuItem, TextField,
} from '@mui/material';
import React from 'react';
import Db from './Db';
import { WindowProps } from '../../App';
import MassDbQuery from './MassDbQuery';
import CloneDb from './CloneDb';

const DbBackupDialog: React.FC<{
    onConfirm: (value: string, withoutData: boolean) => void;
    open: boolean;
    onClose: () => void;
}> = props => {
    const [name, setName] = React.useState('');
    const [withoutData, setWithoutData] = React.useState(false);

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Backup database</DialogTitle>
        <DialogContent>
            <div>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    variant="standard"
                />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={withoutData}
                            onChange={e => setWithoutData(e.target.checked)}
                        />
                    }
                    label="Without data"
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={() => {
                props.onConfirm(name, withoutData);
                props.onClose();
            }}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>;
};

const CreateUserDialog: React.FC<{
    onConfirm: (name: string, password: string) => void;
    open: boolean;
    onClose: () => void;
}> = props => {
    const [name, setName] = React.useState('');
    const [password, setPassword] = React.useState('');

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Create user</DialogTitle>
        <DialogContent>
            <div>
                <TextField
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    variant="standard"
                />
            </div>
            <div>
                <TextField
                    label="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    variant="standard"
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={() => {
                props.onConfirm(name, password);
                props.onClose();
            }}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>;
};

const AddUserToDbDialog: React.FC<{
    onConfirm: (userId: string, dbId: string) => void;
    open: boolean;
    onClose: () => void;
    users: { id: string, name: string }[];
    dbs: { id: string, name: string }[];
}> = props => {
    const [userId, setUserId] = React.useState('');
    const [dbId, setDbId] = React.useState('');

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Create user</DialogTitle>
        <DialogContent>
            <div>
                <TextField select label="User" value={userId} onChange={e => setUserId(e.target.value)}>
                    {props.users.map(user => <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>)}
                </TextField>
            </div>
            <div>
                <TextField select label="Database" value={dbId} onChange={e => setDbId(e.target.value)}>
                    {props.dbs.map(db => <MenuItem key={db.id} value={db.id}>{db.name}</MenuItem>)}
                </TextField>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={() => {
                props.onConfirm(userId, dbId);
                props.onClose();
            }}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>;
};

const Dbs:WindowProps<{id: string}> = props => {
    const { data, loading } = useQuery(gql`
        query($id: String!) {
            getDbms(id: $id) {
                id
                name
                host
                dbs {
                    id
                    name
                    users {
                    id
                    name
                    }
                }
                users {
                    id
                    name
                }
                internalDbs
                internalUsers
            }
        }
      `, {
        variables: {
            id: props.id,
        },
    });

    const [backupDb] = useMutation(gql`
        mutation($dbId: String! $name: String $withoutData: Boolean) {
            backupDb(dbId: $dbId name: $name withoutData: $withoutData) {
                id
                name
            }
        }
    `);

    const [saveDbSchema] = useMutation(gql`
        mutation($dbId: String! $name: String) {
            saveDbSchema(dbId: $dbId name: $name) {
                id
                name
            }
        }
    `);

    const [createDb] = useMutation(gql`
        mutation($db: DbInput! $withoutChange: Boolean) {
            createDb(db: $db withoutChange: $withoutChange) {
                id
                name
            }
        }
    `);

    const [createDbUser] = useMutation(gql`
        mutation($user: DbUserInput! $withoutChange: Boolean) {
            createDbUser(user: $user withoutChange: $withoutChange) {
                id
                name
            }
        }
    `);

    const [addDbUserToDb] = useMutation(gql`
        mutation($userId: String! $dbId: String! $withoutChange: Boolean) {
            addDbUserToDb(userId: $userId dbId: $dbId withoutChange: $withoutChange)
        }
    `);

    const [dbBackupDialogOpen, setDbBackupDialogOpen] = React.useState<string | null>(null);
    const [createUserDialogOpen, setCreateUserDialogOpen] = React.useState(false);
    const [addUserToDbDialogOpen, setAddUserToDbDialogOpen] = React.useState(false);

    if (loading) return <div>Loading...</div>;

    return <div>
        <h2>{`${data.getDbms.name} (host: ${data.getDbms.host})`}</h2>
        <h4>Databases</h4>
        <div>
            <Button
                onClick={() => props.promptDialog({
                    title: 'Create database',
                    onConfirm: value => createDb({
                        variables: {
                            db: {
                                name: value,
                                dbms_id: data.getDbms.id,
                            },
                        },
                    }),
                })}
                variant="contained"
            >
                Create database
            </Button>
            <Button
                onClick={() => setCreateUserDialogOpen(true)}
                variant="contained"
            >
                Create user
            </Button>
            <Button
                onClick={() => setAddUserToDbDialogOpen(true)}
                variant="contained"
            >
                Add user to database
            </Button>
            <Button onClick={() => props.addWindows(
                `Mass query to ${data.getDbms.name}`,
                MassDbQuery,

                { dbmsId: data.getDbms.id },
            )}
            >
Mass db query
            </Button>
            <Button onClick={() => props.addWindows(
                `Clone db in ${data.getDbms.name}`,
                CloneDb,
                { dbmsId: data.getDbms.id },
            )}
            >
Clone db
            </Button>
        </div>
        {data.getDbms.dbs.map(db => <div key={db.id} style={{ paddingBottom: 12 }}>
            <div>
                <MenuItem
                    onClick={() => props.addWindows(db.name, Db, { id: db.id })}
                    style={{ fontWeight: 'bold' }}
                >
                    {db.name}
                </MenuItem>
                <div>
                    <Button onClick={() => {
                        setDbBackupDialogOpen(db.id);
                    }}
                    >
                            Backup
                    </Button>
                    <Button onClick={() => {
                        props.promptDialog({
                            title: 'Save database schema',
                            onConfirm: value => saveDbSchema({ variables: { dbId: db.id, name: value } }),
                        });
                    }}
                    >
                            Save schema
                    </Button>
                </div>
                {db.users.length ? <div>Users:</div> : null}
                <ul>
                    {db.users.map(user => <li key={user.id}>{user.name}</li>)}
                </ul>
            </div>
        </div>)}
        <h4>Internal databases</h4>
        <ul>
            {data.getDbms.internalDbs.map(db => <li key={db}>
                {db}
                {data.getDbms.dbs.find(d => d.name === db) ? null :
                    <Button onClick={() => createDb({
                        variables: {
                            db: {
                                name: db,
                                dbms_id: data.getDbms.id,
                            },
                            withoutChange: true,
                        },
                    })}
                    >
                        Create
                    </Button>}
            </li>)}
        </ul>
        <h4>Users</h4>
        <ul>
            {data.getDbms.users.map(user => <li key={user.id}>{user.name}</li>)}
        </ul>
        <h4>Internal users</h4>
        <ul>
            {data.getDbms.internalUsers.map(user => <li key={user}>{user}</li>)}
        </ul>
        <DbBackupDialog
            open={!!dbBackupDialogOpen}
            onClose={() => setDbBackupDialogOpen(null)}
            onConfirm={(name, withoutData) => {
                backupDb({
                    variables: {
                        dbId: dbBackupDialogOpen,
                        name,
                        withoutData,
                    },
                });
            }}
        />
        <CreateUserDialog
            open={createUserDialogOpen}
            onClose={() => setCreateUserDialogOpen(false)}
            onConfirm={(name, password) => {
                createDbUser({
                    variables: {
                        user: {
                            name,
                            password,
                            dbms_id: data.getDbms.id,
                        },
                    },
                });
            }}
        />
        <AddUserToDbDialog
            open={addUserToDbDialogOpen}
            onClose={() => setAddUserToDbDialogOpen(false)}
            onConfirm={(userId, dbId) => {
                addDbUserToDb({
                    variables: {
                        userId,
                        dbId,
                    },
                });
            }}
            users={data.getDbms.users}
            dbs={data.getDbms.dbs}
        />
    </div>;
};

export default Dbs;
