import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField,
} from '@mui/material';
import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { WindowProps } from '../../App';

interface CloneDbProps {
    dbmsId: string;
}

const CloneDb: WindowProps<CloneDbProps> = props => {
    const [dialog, setDialog] = useState(false);
    const [fromDb, setFromDb] = useState('');
    const [toDb, setToDb] = useState('');
    const [user, setUser] = useState('');

    const dbs = useQuery(gql`
        query ($id: String!) {
            getDbms(id: $id) {
                dbs {
                    id
                    name
                }
                users {
                    id
                    name
                }
            }
        }
    `, {
        variables: {
            id: props.dbmsId,
        },
    });

    const [cloneDb] = useMutation(gql`
        mutation($fromDbId: String!, $toDbId: String!, $fromDbUserId: String) {
            cloneDb(fromDbId: $fromDbId, toDbId: $toDbId, fromDbUserId: $fromDbUserId)
        }
    `);

    if (dbs.loading) {
        return <div>Loading...</div>;
    }

    return <div>
        <div>
From:
            <Select value={fromDb} onChange={e => setFromDb(e.target.value as string)}>
                {dbs.data.getDbms.dbs.map(db => <MenuItem key={db.id} value={db.id}>{db.name}</MenuItem>)}
            </Select>
        </div>
        <div>
To:
            <Select value={toDb} onChange={e => setToDb(e.target.value as string)}>
                {dbs.data.getDbms.dbs.map(db => <MenuItem key={db.id} value={db.id}>{db.name}</MenuItem>)}
            </Select>
        </div>
        <div>
            From user
            <Select value={user} onChange={e => setUser(e.target.value as string)}>
                <MenuItem value={null}>None</MenuItem>
                {dbs.data.getDbms.users.map(user => <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>)}
            </Select>
        </div>
        <div>
            <Button onClick={async () => {
                setDialog(true);
            }}
            >
                Clone
            </Button>
        </div>
        <Dialog open={dialog} onClose={() => setDialog(false)}>
            <DialogTitle>Вы уверены?</DialogTitle>
            <DialogContent>
                <div>
                    {`БД ${dbs.data.getDbms.dbs.find(db => db.id === fromDb)?.name} будет склонирована в ${dbs.data.getDbms.dbs.find(db => db.id === toDb)?.name}${user ? ` от имени пользователя ${
                        dbs.data.getDbms.users.find(u => u.id === user)?.name
                    }` : ''}. Будут созданы бекапы для обеих БД. Целевая БД будет перезаписана.
                `}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialog(false)}>Отмена</Button>
                <Button
                    onClick={async () => {
                        await cloneDb({
                            variables: {
                                fromDbId: fromDb,
                                toDbId: toDb,
                                fromDbUserId: user || null,
                            },
                        });
                        setDialog(false);
                    }}
                    color="error"
                >
                    Склонировать
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
};

export default CloneDb;
