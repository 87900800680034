import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField,
} from '@mui/material';
import { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { WindowProps } from '../../App';

interface MassDbQueryProps {
    dbmsId: string;
}

const MassDbQuery: WindowProps<MassDbQueryProps> = props => {
    const [query, setQuery] = useState('');
    const [dbNames, setDbNames] = useState<string[]>([]);
    const [results, setResults] = useState<any[]>([]);
    const [dialog, setDialog] = useState(false);

    const dbs = useQuery(gql`
        query ($id: String!) {
            getDbms(id: $id) {
                dbs {
                    id
                    name
                }
            }
        }
    `, {
        variables: {
            id: props.dbmsId,
        },
    });

    const [massDbQuery] = useMutation(gql`
        mutation($dbmsId: String!, $dbNames: [String!]!, $query: String!) {
            massDbQuery(dbmsId: $dbmsId, dbNames: $dbNames, query: $query) {
                dbName
                result
                error
            }
        }
    `);

    if (dbs.loading) {
        return <div>Loading...</div>;
    }

    return <div>
        <Select multiple value={dbNames} onChange={e => setDbNames(e.target.value as string[])}>
            {dbs.data.getDbms.dbs.map(db => <MenuItem key={db.id} value={db.name}>{db.name}</MenuItem>)}
        </Select>
        <div>
            <TextField multiline fullWidth value={query} onChange={e => setQuery(e.target.value)} />
        </div>
        <div>
            <Button onClick={async () => {
                setDialog(true);
            }}
            >
                Send
            </Button>
        </div>
        <table>
            {results.map((result, i) => <tr key={i}>
                <td>{result.dbName}</td>
                <td style={{ width: '100%' }}>
                    <div style={{ height: 200, overflow: 'auto' }}>
                        {result.result ?
                            <pre>
                                {JSON.stringify(JSON.parse(result.result), null, 2)}
                            </pre> : <pre>
                                {result.error}
                            </pre>}
                    </div>
                </td>
            </tr>)}
        </table>
        <Dialog open={dialog} onClose={() => setDialog(false)}>
            <DialogTitle>Вы уверены?</DialogTitle>
            <DialogContent>
                <div>Запрос</div>
                <div>
                    <pre>{query}</pre>
                </div>
                <div>Будет применен к БД:</div>
                {dbNames.map(dbName => <div key={dbName}>{dbName}</div>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialog(false)}>Отмена</Button>
                <Button
                    onClick={async () => {
                        const res = await massDbQuery({
                            variables: {
                                dbmsId: props.dbmsId,
                                dbNames,
                                query,
                            },
                        });
                        setResults(res.data.massDbQuery);
                        setDialog(false);
                    }}
                    color="error"
                >
                    Отправить
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
};

export default MassDbQuery;
